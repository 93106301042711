import { _firestore } from '../../firebase';
import localStorage from 'local-storage';
import ActionTypes from './constants';
import moment from 'moment-timezone';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';
import {
  getAllSpecialsPromise,
  getSoldOutMenuItemsPromise,
  getPopularsPromise,
  getOrderSetupPromise,
  getMenuItemSchedules,
  getMenuItemSchedulesPromise,
  getCategoriesPromise,
  getMenuItemsPromise,
  getOrderSetup,
  getSoldOutMenuItems,
  watchStoreMenuHashes,
  getUpdatedDataFromFile,
  getUpdatedStoreMenuUrls,
} from './methods';
import { selectStoreConfig } from '../../containers/Layout/selectors';
import store from '../../store';
import { API_URL } from 'configuration/urls';
import { fetchMenuItemData } from 'helpers/fetchMenuItemData';
import removeTruthyProperties from 'helpers/removeTruthyProperties';

function fetchDataOrUpdateFromFile(
  type,
  storeId,
  storageUrl,
  fetchPromise,
  isDisabled = false
) {
  if (!storageUrl) {
    return fetchPromise(storeId, isDisabled);
  } else {
    return getUpdatedDataFromFile(type, storeId, storageUrl, isDisabled);
  }
}

export function updateMenuItems(menuItems, populars, menuItemSchedules) {
  if (menuItemSchedules?.length > 0) {
    let notAvailableMenuItems = {};
    menuItemSchedules?.forEach(({ menuItemId, isAvailableNow }) => {
      if (!notAvailableMenuItems[menuItemId]) {
        notAvailableMenuItems[menuItemId] = isAvailableNow;
      }
    });
    notAvailableMenuItems = removeTruthyProperties(notAvailableMenuItems);
    const updatedMenuItems = menuItems?.filter(({ _id }) => {
      return !notAvailableMenuItems[_id];
    });
    const updatedPopulars = populars?.filter(({ _id }) => {
      return !notAvailableMenuItems[_id];
    });
    return { menuItems: updatedMenuItems, populars: updatedPopulars };
  }
  return { menuItems, populars };
}

function initHome2(storeId) {
  return async function (dispatch, getState) {
    const { orderSetup: cachedOrderSetup } = getState().homeReducer ?? {};
    const { isPopularDisabled = false } = cachedOrderSetup || {};

    let [
      orderSetup,
      {
        popularsStorageUrl,
        categoriesStorageUrl,
        menuItemsStorageUrl,
        specialsStorageUrl,
      },
      menuItemSchedules,
      soldOutMenuItems,
      { menuItemSizes, menuItemSubModifiers },
    ] = await Promise.all([
      getOrderSetupPromise(storeId),
      getUpdatedStoreMenuUrls(storeId),
      getMenuItemSchedulesPromise(storeId),
      getSoldOutMenuItemsPromise(storeId),
      fetchMenuItemData(storeId),
    ]);

    const populars = await fetchDataOrUpdateFromFile(
      'populars',
      storeId,
      popularsStorageUrl,
      getPopularsPromise,
      isPopularDisabled
    );

    const categories = await fetchDataOrUpdateFromFile(
      'categories',
      storeId,
      categoriesStorageUrl,
      getCategoriesPromise
    );

    if (categories?.length > 0) {
      categories.sort((a, b) => a.order - b.order);
    }

    const menuItems = await fetchDataOrUpdateFromFile(
      'menuItems',
      storeId,
      menuItemsStorageUrl,
      getMenuItemsPromise
    );

    const allSpecials = await fetchDataOrUpdateFromFile(
      'specials',
      storeId,
      specialsStorageUrl,
      getAllSpecialsPromise
    );

    const { menuItems: updatedMenuItems, populars: updatedPopulars } =
      updateMenuItems(menuItems, populars, menuItemSchedules);

    dispatch({
      type: ActionTypes.InitializeHome,
      orderSetup,
      categories,
      allPopulars: populars,
      populars: updatedPopulars,
      allMenuItems: menuItems,
      menuItems: updatedMenuItems,
      allSpecials,
      menuItemSchedules,
      soldOutMenuItems,
      menuItemSizes,
      menuItemSubModifiers,
    });

    setTimeout(() => {
      dispatch({
        type: ActionTypes.InitializeHome,
        isMinifiedVersion: false,
      });
      dispatch(getMenuItemSchedules(storeId));
      dispatch(getOrderSetup(storeId));
      dispatch(getSoldOutMenuItems(storeId));

      // watch timestamps for all collections: [populars, categories, menuItems, specials]
      dispatch(watchStoreMenuHashes(storeId, getState));
    }, 0);
  };
}

export function initHome(storeId, restaurantId) {
  return async function (dispatch) {
    if (!storeId || !restaurantId) return;

    const orderSetup = window.OrderSetup;
    const categories = window.categoriesList;
    const minifiedMenuItems = window.MinifiedMenuItems;
    const minifiedSpecials = window.MinifiedSpecials?.map((special) => {
      return { ...special, isMinifiedSpecial: true };
    });
    let populars = window.Populars;

    const { isPopularDisabled = false } = orderSetup || {};
    const categoryIds = categories.map((c) => c._id);

    if (isPopularDisabled) {
      populars = [];
    } else {
      populars = populars
        ?.map((popular) => {
          const menuItem = minifiedMenuItems?.find(
            (m) =>
              m._id === popular.menuItemId && categoryIds.includes(m.categoryId)
          );

          return {
            ...menuItem,
          };
        })
        .filter((p) => Boolean(p?._id));
    }

    if (minifiedSpecials?.some(({ isPublished }) => isPublished)) {
      const specialsCategory = {
        name: orderSetup?.customSpecialTitle || 'specials',
        _id: 'specials',
        isSpecial: true,
        displayOrder: 1,
      };
      if (categories[0]._id === 'popular') {
        categories.splice(1, 0, specialsCategory);
      }
    }

    // NOTE: In first initHome we will only set default values for most of the fields
    dispatch({
      type: ActionTypes.InitializeHome,
      categories,
      allPopulars: populars,
      populars,
      allSpecials: minifiedSpecials,
      allMenuItems: minifiedMenuItems,
      menuItems: minifiedMenuItems,
      menuItemSchedules: [],
      soldOutMenuItems: [],
      itemSizes: [],
      menuItemSizes: [],
      menuItemSubModifiers: [],
      orderSetup: orderSetup ?? {},
      isMinifiedVersion: true,
    });

    setTimeout(() => {
      dispatch(initHome2(storeId));
    }, 0);
  };
}

export function updateMenuItemSchedules(menuItemSchedules) {
  return async function (dispatch) {
    const { menuItems, populars } = updateMenuItems(
      store?.getState()?.homeReducer?.allMenuItems,
      store?.getState()?.homeReducer?.allPopulars,
      menuItemSchedules
    );

    dispatch({
      type: ActionTypes.UpdateMenuItemSchedules,
      menuItemSchedules,
      menuItems,
      populars,
    });
  };
}

export function updateSoldOutMenuItems(soldOutMenuItems) {
  return async function (dispatch) {
    dispatch({
      type: ActionTypes.UpdateSoldOutMenuItems,
      soldOutMenuItems,
    });
  };
}

export function updateOrderSetup(orderSetup) {
  return async function (dispatch) {
    dispatch({
      type: ActionTypes.InitializeHome,
      orderSetup,
    });
  };
}

export function getUserData() {
  const {
    _id = '',
    mobileNumber = '',
    storeId = '',
    restaurantId = '',
    firstName = '',
    lastName = '',
    userId = '',
  } = store.getState()?.homeReducer?.userDetails || {};
  const { Aa = '' } = store.getState()?.homeReducer?.currentUser || {};

  const data = {
    userId: _id,
    mobileNumber,
    storeId,
    restaurantId,
    firstName,
    lastName,
    token: Aa,
    originalUserId: userId,
  };

  return data;
}

window.getUserData = () => {
  const userData = getUserData();
  const data = {
    type: 'userdata',
    userdata: {
      ...userData,
    },
  };
  window.ReactNativeWebView?.postMessage?.(JSON.stringify(data));
  return userData;
};

export const postUserDataToRNWebView = (type = 'login') => {
  const userData = getUserData();
  const data = {
    type,
    userdata: {
      ...userData,
    },
  };
  window.ReactNativeWebView?.postMessage?.(JSON.stringify(data));
  return data;
};

export function getCurrentUseDetails(
  user,
  newUser,
  storeId,
  restaurantId,
  isLogin
) {
  return (dispatch) => {
    dispatch(isFetchingCustomers(true));
    _firestore
      .collection('Users')
      .where('restaurantId', '==', restaurantId)
      .where('uid', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ ...doc.data(), _id: doc.id });
        });
        if (dataArray.length <= 0 && newUser) {
          let reffUser = _firestore.collection('Users').doc();
          newUser.uid = user.uid;
          newUser._id = reffUser.id;
          newUser.isNewCustomer = true;
          reffUser.set(newUser).then((_doc) => {
            _firestore
              .collection('PathwayToOffers')
              .where('restaurantId', '==', restaurantId)
              .where('userId', '==', reffUser.id)
              .where('isUsed', '==', false)
              .get()
              .then((querySnapshotI) => {
                var dataArrayI = [];
                querySnapshotI.forEach((docI) => {
                  dataArrayI.push({ _id: docI.id, ...docI.data() });
                });

                dispatch(
                  allCustomersFetched(user, newUser, dataArrayI, isLogin)
                );
                dispatch(isFetchingCustomers(false));
              })
              .catch((err) => {
                console.error(err);
                dispatch(isFetchingCustomers(false));
              });
          });
        } else {
          if (
            dataArray &&
            dataArray.length > 0 &&
            dataArray[0] &&
            dataArray[0]._id
          ) {
            _firestore
              .collection('PathwayToOffers')
              .where('restaurantId', '==', restaurantId)
              .where('userId', '==', dataArray[0]._id)
              .where('isUsed', '==', false)
              .onSnapshot((querySnapshotI) => {
                var dataArrayI = [];
                querySnapshotI.forEach((docI) => {
                  dataArrayI.push({ _id: docI.id, ...docI.data() });
                });
                dispatch(
                  allCustomersFetched(user, dataArray[0], dataArrayI, isLogin)
                );
                dispatch(isFetchingCustomers(false));
              });
          } else {
            dispatch(isFetchingCustomers(false));
          }
        }
      });
  };
}

export function setState(newState) {
  return {
    type: `@home/setState`,
    payload: newState,
  };
}

export function updateCurrentOrderObj(id, obj) {
  return (dispatch, getState) => {
    const storeConfig = selectStoreConfig(getState());
    try {
      if (window.localStorage) {
        if (localStorage.get('currentOrder')) {
          obj.date = moment()
            .tz(storeConfig.timeZone)
            .format('YYYY-MM-DD HH:mm');
          localStorage.set('currentOrder', obj);
          dispatch(orderUpdated(obj));
        } else {
          dispatch(orderUpdated(obj));
        }
      } else {
        dispatch(orderUpdated(obj));
      }
    } catch (err) {
      dispatch(orderUpdated(obj));
    }
  };
}

export function fetchOrder(orderId) {
  return (dispatch) => {
    let orderRef = _firestore.collection('Orders').doc(orderId);
    orderRef.onSnapshot((querySnapshot) => {
      let objj = querySnapshot.data();
      if (objj) {
        objj._id = querySnapshot.id;
        try {
          if (window.localStorage) {
            if (localStorage.get('currentOrder')) {
              localStorage.set('currentOrder', objj);
              dispatch(orderUpdated(objj));
            } else {
              dispatch(orderUpdated(objj));
            }
          } else {
            dispatch(orderUpdated(objj));
          }
        } catch (err) {
          dispatch(orderUpdated(objj));
        }
      }
    });
  };
}

export function addReservation(reservation, currentUser) {
  return (dispatch, getState) => {
    const storeConfig = selectStoreConfig(getState());
    reservation.userId = currentUser._id;
    reservation.storeId = storeConfig.storeId;
    reservation.restaurantId = storeConfig.restaurantId;
    reservation.firstName = currentUser.firstName;
    reservation.lastName = currentUser.lastName;
    reservation.userName = currentUser.firstName + ' ' + currentUser.lastName;
    reservation.mobileNumber = getFormattedMobileNumber(
      currentUser.mobileNumber
    );
    reservation.date = moment()
      .tz(storeConfig.timeZone)
      .format('YYYY-MM-DD HH:mm');
    reservation.isProcessedForCommision = false;
    let now = new Date();
    reservation.dateTimestamp = now.getTime();
    _firestore
      .collection('Reservations')
      .add(reservation)
      .then((doc) => {
        let ref = _firestore.collection('Reservations').doc(doc.id);
        ref.get().then((querySnapshot) => {
          let data = querySnapshot.data();
          try {
            dispatch(reservationFetched(data));
          } catch (err) {}
        });
      });
  };
}

export function takeSignOutAction() {
  return (dispatch) => {
    dispatch(signOutAction());
  };
}

export function filterView(filter) {
  return {
    type: ActionTypes.FilterView,
    ...filter,
  };
}

export function voucherFilterView(filter) {
  return {
    type: ActionTypes.FilterVoucherView,
    ...filter,
  };
}

export function orderUpdated(currentOrder) {
  return {
    type: ActionTypes.LoadCurrentOrder,
    currentOrder,
  };
}

function reservationFetched(currentReservations) {
  return {
    type: ActionTypes.FetchedReservations,
    currentReservations,
  };
}

function isFetchingCustomers(isFetching) {
  return {
    type: ActionTypes.FetchUserByUidLoading,
    isFetching,
  };
}

function allCustomersFetched(
  currentUser,
  userDetails,
  pathwayToOffers,
  isLogin
) {
  if (isLogin) {
    setTimeout(() => {
      postUserDataToRNWebView();
    }, 50);
  }
  return {
    type: ActionTypes.FetchUserByUid,
    userDetails,
    currentUser,
    pathwayToOffers,
  };
}

function signOutAction() {
  postUserDataToRNWebView('logout');
  return {
    type: ActionTypes.FetchUserByUid,
    userDetails: undefined,
    currentUser: undefined,
    pathwayToOffers: [],
  };
}

export function deleteAccountAction(callback) {
  return async (dispatch) => {
    const { restaurantId, originalUserId } = getUserData();
    const requestObj = {
      userId: originalUserId,
      restaurantId,
    };
    const response = await fetch(`${API_URL}api/v1/delete`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObj),
    });
    const data = await response.json();
    if (data.code === 200) {
      if (callback) callback();
    }
  };
}

export function toggleMobileCart(payload) {
  return { type: ActionTypes.ToggleMobileCart, payload };
}

export function setLayoutFlow(value) {
  return { type: ActionTypes.SetLayoutFlow, value };
}

export function setNotes(notes) {
  return { type: ActionTypes.SetNotes, notes };
}

export function setBookingNotes(bookingNotes) {
  return { type: ActionTypes.SetBookingNotes, bookingNotes };
}

export function setSlideIndex(value) {
  return {
    type: ActionTypes.SetSlideIndex,
    value,
  };
}

export function setMenuLoading(value) {
  return { type: ActionTypes.SetMenuLoading, value };
}

export function setUserSelectedVoucherItems(value) {
  return {
    type: ActionTypes.SetUserSelectedVoucherItems,
    value,
  };
}
