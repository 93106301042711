import { API_URL } from 'configuration/urls';

export const getDineInObjects = async (storeId) => {
  const response = await fetch(
    `${API_URL}stores/v1/floorLayouts?filter.storeId=${storeId}`,
    {
      method: 'get',
    }
  );
  const result = await response.json();
  const { data, errors = [] } = result;
  if (errors.length > 0) {
    return false;
  }
  if (data?.floorLayouts?.length) {
    let floors = {};
    let tables = [];
    result.data.floorLayouts.forEach(({ dineInObjects, id, name }) => {
      const dineInObjs = dineInObjects
        .map((item) => {
          return {
            id: item.id,
            number: item.number,
            name: item.name,
            floorLayoutId: id,
            floorName: name,
          };
        })
        .sort((a, b) => a.number - b.number);
      tables.push(...dineInObjs);
      floors[id] = { name, tables: dineInObjs };
    });
    return { floors, tables };
  }
};
