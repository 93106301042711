import {
  MENU_ORDER_FROM,
  MENU_ORDER_STATUS,
  MENU_ORDER_TYPE,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';
import ActionTypes from './constants';
import localStorage from 'local-storage';

const initialState = {
  populars: [],
  categories: [],
  allSpecials: [],
  menuItems: [],
  orderSetup: {},
  notes: '',
  currentOrder: {
    address: '',
    addressLocation: {},
    orderStatus: MENU_ORDER_STATUS.unconfirmed,
    orderFrom: MENU_ORDER_FROM.web,
    orderType: MENU_ORDER_TYPE.pickup,
    specials: [],
    menuItems: [],
    stage: ORDER_STAGE_TYPES.NONE,
    totalCost: 0,
    payableAmount: 0,
    isAllowToConfirm: false,
    /* date: moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'), */
  },
  ui: {
    mobileCart: false,
    layoutFlow: false,

    slideIndex: 0,
    aboutOpen: false,
    bookingOpen: false,
    reviewOpen: false,
    reservationOpen: false,
    showMenuLoading: false,
  },
  specialFilter: {
    isSpecialFilterApplied: false,
    spMenuItems: [],
    spMenuSizes: [],
    spSubModifiers: [],
    spMenuCategories: [],
    boxIndex: -1,
    itemExist: -1,
  },
  voucherFilter: {
    isVoucherFilterApplied: false,
    voucherMenuItems: [],
    voucherMenuSizes: [],
    voucherSubModifiers: [],
    voucherMenuCategories: [],
    boxIndex: -1,
    itemExist: -1,
  },
  userSelectedVoucherItems: localStorage.get('userSelectedVoucherItems'),
  storeMenuHashes: {},
};

const homeReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    /* XXX: potential suburb areaCode fix
     * case 'INITIALIZE_ORDER': 
      const { currentOrder } = state;
      // fix for miltistore redirect area / areaCode issue
      if (currentOrder && currentOrder.address && currentOrder.suburbId && !currentOrder.areaCode) {
        console.log('multistore areaCode missing for suburb', currentOrder.suburbId, payload.allSuburbs);
        const mutistoreSuburb = payload.allSuburbs.find(s => s._id === currentOrder.suburbId);
        console.log('found suburb', mutistoreSuburb);
        if (mutistoreSuburb) {
          const { areaCode, name: area } = mutistoreSuburb;
          if (!mutistoreSuburb)
          return {
            ...state,
            currentOrder: {
              ...currentOrder,
              areaCode: areaCode || '',
              area,
            }
          }
        } else {
          console.warn('cannot find suburb id', currentOrder.suburbId, 'after multistore redirect')
          console.warn('order missing area/areaCode');
        }
      }
      return {
        ...state,
      }; */
    case ActionTypes.InitializeHome:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.RefreshedData:
      return {
        ...state,
        ...payload?.data,
      };
    case ActionTypes.RefreshedUrls:
      return {
        ...state,
        storeMenuHashes: { ...state.storeMenuHashes, ...payload?.data },
      };
    case `@home/setState`:
      if (payload.payload.currentOrder) {
        window.localStorage.setItem(
          'currentOrder',
          JSON.stringify(payload.payload.currentOrder)
        );
      }
      return {
        ...state,
        ...payload.payload,
      };

    case ActionTypes.UpdateMenuItemSchedules:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.UpdateSoldOutMenuItems:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.FetchUserByUidLoading:
      return {
        ...state,
        isFetchingUser: payload.isFetching,
      };
    case ActionTypes.FetchUserByUid:
      return {
        ...state,
        // TODO: This condition doesn't make sense
        ...(!state.currentUser && state.currentOrder.stage === 1
          ? {
              currentOrder: {
                ...state.currentOrder,
                stage: ORDER_STAGE_TYPES.NONE,
              },
            }
          : {}),
        currentUser: payload.currentUser,
        userDetails: payload.userDetails,
        pathwayToOffers: payload.pathwayToOffers,
      };
    case ActionTypes.LoadCurrentOrder:
      return { ...state, currentOrder: payload.currentOrder };
    case ActionTypes.FetchedReservations:
      return { ...state, currentReservations: payload.currentReservations };
    case ActionTypes.FilterView:
      return {
        ...state,
        ...(state.specialFilter.isSpecialFilterApplied
          ? { prevSpecialFilter: state.specialFilter }
          : { prevSpecialFilter: { ...payload } }),
        specialFilter: { ...state.specialFilter, ...payload },
      };
    case ActionTypes.FilterVoucherView:
      return {
        ...state,
        ...(state.voucherFilter.isVoucherFilterApplied
          ? { prevSpecialFilter: state.voucherFilter }
          : { prevSpecialFilter: { ...payload } }),
        voucherFilter: { ...state.voucherFilter, ...payload },
      };
    case ActionTypes.ToggleMobileCart:
      return {
        ...state,
        ui: {
          ...state.ui,
          mobileCart: payload.payload ?? !state.ui.mobileCart,
        },
      };
    case ActionTypes.SetLayoutFlow:
      return {
        ...state,
        ui: { ...state.ui, layoutFlow: payload.value },
      };
    case ActionTypes.SetNotes:
      return {
        ...state,
        ...(state.currentOrder && {
          currentOrder: { ...state.currentOrder, notes: payload.notes },
        }),
        notes: payload.notes,
      };
    case ActionTypes.SetBookingNotes:
      return {
        ...state,
        bookingNotes: payload.bookingNotes,
      };
    case ActionTypes.SetSlideIndex:
      return {
        ...state,
        ui: { ...state.ui, slideIndex: payload.value },
      };
    case ActionTypes.SetMenuLoading:
      return {
        ...state,
        ui: { ...state.ui, showMenuLoading: payload.value },
      };
    case ActionTypes.SetUserSelectedVoucherItems:
      if (payload.value)
        window.localStorage.setItem(
          'userSelectedVoucherItems',
          JSON.stringify(payload.value)
        );
      return {
        ...state,
        userSelectedVoucherItems: payload.value,
      };
    default:
      return state;
  }
};

export default homeReducer;
