import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { validateVoucher } from '../../components/OrderReview/voucherHelper';
import { getTimestampForMomentDate } from '../timeHelper';

import {
  detectHalfMergeRequired,
  detectMergeRequired,
  detectOffers,
  detectSpecial,
  getItemPrice,
  getItemsInCart,
  getVoucherDiscountedAmount,
  performMerge,
} from '@next-order/next-order-engine';
import {
  MENU_ITEM_TYPE,
  MENU_ORDER_TYPE,
  ORDER_PAYMENT_TYPE,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';
import getCardCharges from 'helpers/getCardCharges';
import removeTruthyProperties from 'helpers/removeTruthyProperties';
import { isMenuItemSoldOut } from 'helpers/detectSoldOutItemsInCart';

/* itemsUsed */

/* ===========================>⛽️Utils⛽️<========================== */
/**
 * @param menuItems
 * @param currentItem
 */
/* #008000 Done ✅ */
export function reMergeItems(menuItems, currentItem) {
  let items = menuItems;
  let mergedIndexes = false;
  menuItems.forEach((m) => {
    if (
      m._id === currentItem._id &&
      currentItem.orderIndex !== m.orderIndex &&
      !mergedIndexes
    ) {
      if (detectMergeRequired(currentItem, m)) {
        m = performMerge(currentItem, m);
        mergedIndexes = true;
      }
    }
  });
  if (mergedIndexes) {
    items = menuItems.filter((f) => f.orderIndex !== currentItem.orderIndex);
  }
  return items;
}

/* =================================><================================== */
/**
 * @param menuItems
 * @param firstHalf
 */
/* #008000 Done ✅ */
export function reMergeHalfItems(menuItems, firstHalf) {
  let items = menuItems;
  let mergedIndexes = false;
  let secondHalfs = menuItems.filter((o) => o._id === firstHalf._idHalf);
  menuItems.forEach((m) => {
    if (
      m._id === firstHalf._id &&
      firstHalf.halfIndex !== m.halfIndex &&
      !mergedIndexes &&
      secondHalfs.length > 0
    ) {
      let secondHalf = secondHalfs[0];
      let existingItemsSH = menuItems.filter(
        (o) => o._id === secondHalf._id && o.halfIndex !== secondHalf.halfIndex
      );
      if (
        existingItemsSH.length &&
        detectHalfMergeRequired(firstHalf, secondHalf, m, existingItemsSH[0])
      ) {
        let ff = performMerge(firstHalf, m);
        mergedIndexes = true;
        items.forEach((i) => {
          if (i._id === ff._id) {
            i = { ...ff };
          }
        });
        let ss = performMerge(secondHalf, existingItemsSH[0]);
        items.forEach((i) => {
          if (i._id === ss._id) {
            i = { ...ss };
          }
        });
      }
    }
  });
  if (mergedIndexes) {
    items = items.filter((f) => f.halfIndex !== firstHalf.halfIndex);
  }
  return items;
}

/* =================================><================================== */
/**
 * @param currentOrder props.currentOrder
 */
export function getNextHalfIndex(currentOrder) {
  const halfItems = currentOrder.menuItems.filter((i) => i.isHalf);
  const currentIndexes = halfItems.map((i) => i.halfIndex);
  const ret = currentIndexes.length ? Math.max(...currentIndexes) + 1 : 0;
  return ret;
}

/* ===========================>🪢Props Usage🪢<========================== */

/* ===========================>⭐️Engine's Main Functions⭐️<========================== */
/**
 * @param orderObj order object
 * @param props
 */
export function engineGetOrderCalculations(orderObj, props) {
  const {
    storeConfig,
    orderSetup,
    order,
    autoVouchers,
    publicHolidays,
    currentOrder,
    halfHalfSetup,
    offers,
    creditCards,
    pathwayToOffers,
    allSpecials,
    userDetails,
    selectedSpecialId,
    userSelectedVoucherItems,
  } = props;

  let currentOd = JSON.parse(JSON.stringify({ ...currentOrder, ...orderObj }));

  const { menuItems } = currentOd;

  let deliveryCost = 0;
  let voucherDiscount = 0;
  let loyaltyDiscount = 0;
  let upsalePrice = 0;
  let specialDiscount = 0;
  let creditCardCharges = 0;
  let platformSurchargeAmt = 0;
  let platformSurchargePercentage = 0;
  let isPlatformSurchargeLevied = false;
  let tax = 0;
  let totalCost = menuItems.reduce(
    (acc, m) => (acc += parseFloat(getItemPrice(m, halfHalfSetup))),
    0
  );
  let payableAmount = Number(totalCost.toFixed(2));

  menuItems.forEach((m, i) => {
    m.orderIndex = i;
  });

  if (!currentOd?.voucherId && autoVouchers?.length > 0) {
    const otMap = { 1: 'Pickup', 2: 'Delivery', 3: 'DineIn' };
    const { orderType } = currentOd;
    const suffix = otMap[orderType];
    let matchingVouchers = autoVouchers.filter(
      (f) => f[`isAvailable${suffix}`]
    );
    if (matchingVouchers.length > 0) {
      let selectedVoucher = matchingVouchers[0];
      currentOd.voucherId = selectedVoucher._id;
      currentOd.selectedVoucher = selectedVoucher;
    }
  }

  const itemsInCart = getItemsInCart(currentOd);

  let rowItems = sortBy(itemsInCart, 'price').reverse();

  // Loyalty discount
  let { offerFrames, itemsUsedForReward } = detectOffers(
    currentOd,
    rowItems,
    offers,
    pathwayToOffers,
    []
  );
  let rewards = offerFrames.flatMap((p) => p.rewards);
  let discount = rewards.reduce(
    (total, obj) => (total += Number(obj.discount)),
    0
  );

  const canAvailLoyaltyDiscount =
    discount > 0 && payableAmount >= Number(offerFrames[0]?.minOrderValue || 0);

  if (canAvailLoyaltyDiscount) {
    payableAmount = Number((payableAmount - Number(discount)).toFixed(2));
    loyaltyDiscount = parseFloat(discount).toFixed(2);
    rowItems = rowItems.map((p) => {
      p.isUsedForReward = itemsUsedForReward.includes(p.primaryKey) ?? false;
      return p;
    });
  } else {
    rewards = [];
  }

  rowItems = rowItems.filter((f) => !(f.isSecondHalf || f.isUsedForReward));

  // Calculate speical's discount
  let specialObj = detectSpecial(
    currentOd,
    rowItems,
    allSpecials,
    orderSetup,
    publicHolidays,
    storeConfig,
    selectedSpecialId
  );
  rowItems = specialObj.rowItems;

  let specials = specialObj.specials;
  if (specials) {
    let discount = 0;
    specials.forEach((s) => {
      discount = discount + Math.abs(Number(s.discount));
      upsalePrice = upsalePrice + Number(s.upsalePrice);
    });
    if (discount > 0) {
      payableAmount = Number(
        (payableAmount - (Number(discount) - Number(upsalePrice))).toFixed(2)
      );
      specialDiscount = parseFloat(
        Number(discount) - Number(upsalePrice)
      ).toFixed(2);
    } else {
      specials = [];
    }
  }
  currentOd.specials = specials;
  currentOd.payableAmount = payableAmount;

  // Calculate voucher's discount
  let matchingVouchers = [];
  if (currentOd.voucherId) {
    let myVouchers = currentOd.selectedVoucher
      ? [currentOd.selectedVoucher]
      : [];
    const otMap = { 1: 'Pickup', 2: 'Delivery', 3: 'DineIn' };
    const { orderType } = currentOd;
    const suffix = otMap[orderType];
    myVouchers = myVouchers.filter((f) => f[`isAvailable${suffix}`]);
    matchingVouchers = myVouchers.filter((v) => v._id === currentOd.voucherId);
    if (matchingVouchers.length > 0) {
      let selectedVoucher = matchingVouchers[0];
      if (
        validateVoucher(
          storeConfig,
          selectedVoucher,
          currentOd,
          payableAmount,
          userDetails
        ).success
      ) {
        const orderObj = currentOd ?? currentOrder;
        const rItems = rowItems ?? getItemsInCart(orderObj);
        const sDiscount = specialDiscount ?? orderObj.specialDiscount;

        let voucherDiscountObj = getVoucherDiscountedAmount(
          selectedVoucher,
          orderObj,
          rItems,
          sDiscount,
          allSpecials,
          orderSetup,
          publicHolidays,
          storeConfig
        );
        if (voucherDiscountObj.isVoucherValid) {
          // todo: add this logic inside @next-order/next-order-engine
          let voucherQuantity = 0,
            voucherMenuItems = [];
          if (userSelectedVoucherItems && userSelectedVoucherItems.length > 0) {
            let totalDiscount = 0;
            var userSelectedVoucherItemsMap = userSelectedVoucherItems.reduce(
              function (map, obj) {
                map[obj._id] = obj;
                return map;
              },
              {}
            );
            const selectedVoucherIdItems = currentOd.menuItems.filter(
              (item) => item.voucherId === selectedVoucher._id
            );
            selectedVoucher?.selectedCategories.forEach((item) => {
              voucherMenuItems.push(...voucherMenuItems, ...item.menuItems);
              voucherQuantity += Number(item.quantity);
            });
            const activeVoucherMenuItems = voucherMenuItems.filter((item) =>
              Boolean(item.isActive)
            );
            const discountedPrice = Number(selectedVoucher?.discountedPrice);
            selectedVoucherIdItems.forEach((item) => {
              const { _id } = item;
              const voucherItem = userSelectedVoucherItemsMap[_id];
              const itemPrice = Number(getItemPrice(item, halfHalfSetup));
              if (!voucherItem) return;
              if (
                activeVoucherMenuItems &&
                activeVoucherMenuItems.length === 1
              ) {
                if (item.quantity <= Number(voucherQuantity)) {
                  totalDiscount +=
                    item.quantity * (itemPrice / item.quantity) -
                    discountedPrice * item.quantity;
                } else {
                  totalDiscount +=
                    Number(voucherQuantity) * (itemPrice / item.quantity) -
                    discountedPrice * Number(voucherQuantity);
                }
              } else {
                if (item.quantity <= voucherItem.quantity) {
                  totalDiscount +=
                    item.quantity * (itemPrice / item.quantity) -
                    discountedPrice * item.quantity;
                } else {
                  totalDiscount +=
                    voucherItem.quantity * (itemPrice / item.quantity) -
                    discountedPrice * voucherItem.quantity;
                }
              }
              totalDiscount =
                totalDiscount - item.extraPrice * voucherItem.quantity;
            });
            voucherDiscountObj.discount = totalDiscount;
          }

          if (
            selectedVoucher.type === '3' &&
            !selectedVoucher.isAvailableWithSpecials
          ) {
            voucherDiscount = Number(voucherDiscountObj.discount).toFixed(2);
            if (voucherDiscountObj.isItemOccupiedWithSpecials) {
              //Find matching special
              specials = voucherDiscountObj.specials;
              if (specials) {
                let discount = 0;
                payableAmount = Number(totalCost.toFixed(2));
                specials.forEach((s) => {
                  discount = discount + Number(s.discount);
                });
                if (discount > 0) {
                  payableAmount = Number(
                    (payableAmount - Number(discount)).toFixed(2)
                  );
                  specialDiscount = parseFloat(discount).toFixed(2);
                } else {
                  specials = [];
                }
              }
            }
            if (payableAmount >= Number(voucherDiscount)) {
              payableAmount = payableAmount - voucherDiscount;
            } else {
              voucherDiscount = payableAmount.toFixed(2);
              payableAmount = 0;
            }
          } else {
            voucherDiscount = Number(voucherDiscountObj.discount).toFixed(2);
            if (payableAmount >= Number(voucherDiscount)) {
              payableAmount = payableAmount - voucherDiscount;
            } else {
              voucherDiscount = payableAmount.toFixed(2);
              payableAmount = 0;
            }
          }
        } else {
          currentOd.voucherDiscount = '0';
          currentOd.voucherId = '';
          currentOd.voucherName = '';
          currentOd.selectedVoucher = {};
        }
      } else {
        // Reset voucher details if there is mo matching voucher in master list
        currentOd.voucherDiscount = '0';
        currentOd.voucherId = '';
        currentOd.voucherName = '';
        currentOd.selectedVoucher = {};
      }
    } else {
      // Reset voucher details if there is mo matching voucher in master list
      currentOd.voucherDiscount = '0';
      currentOd.voucherId = '';
      currentOd.voucherName = '';
      currentOd.selectedVoucher = {};
    }
  } else {
    currentOd.voucherId = '';
    currentOd.voucherDiscount = '0';
    currentOd.voucherName = '';
    currentOd.selectedVoucher = {};
  }

  // Calculate delivery fee
  if (
    currentOd.orderType === MENU_ORDER_TYPE.delivery &&
    currentOd.menuItems.length > 0
  ) {
    if (
      orderSetup?.isDeliveryFreeOver &&
      Number(orderSetup?.deliveryFreeOver) < payableAmount
    ) {
      deliveryCost = 0;
    } else if (orderSetup?.useRadiusBasedDeliveryAreaCalulation) {
      deliveryCost = currentOd?.deliveryData?.deliveryCost || '0';
    } else {
      if (order.allSuburbs && currentOd.suburbId) {
        let subs = order.allSuburbs.filter((s) => {
          return s._id === currentOd.suburbId;
        });
        if (subs.length > 0) {
          deliveryCost = subs[0]?.deliveryCost || 0;
        } else {
          deliveryCost = 0;
        }
      } else {
        deliveryCost = 0;
      }
    }
  }

  if (
    orderSetup?.isFirstDeliveryFree &&
    (!userDetails?.totalDeliveryOrders ||
      (userDetails?.totalDeliveryOrders &&
        Number(userDetails?.totalDeliveryOrders) <= 0))
  ) {
    deliveryCost = 0;
  }

  payableAmount = payableAmount + Number(deliveryCost);

  if (
    currentOd.deliveryDate &&
    orderSetup?.isOptedInForHolidaySurcharge &&
    orderSetup?.publicHolidaySurcharge &&
    publicHolidays?.length > 0
  ) {
    let dates = publicHolidays.filter((m) => {
      return (
        m ===
        moment(currentOd.deliveryDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
      );
    });
    if (dates.length > 0) {
      let publicHolidaySurcharge = 0;
      publicHolidaySurcharge =
        (payableAmount * Number(orderSetup.publicHolidaySurcharge)) / 100;
      payableAmount = payableAmount + publicHolidaySurcharge;
      currentOd.publicHolidaySurcharge = parseFloat(
        publicHolidaySurcharge
      ).toFixed(2);
      currentOd.publicHolidaySurchargePer = orderSetup.publicHolidaySurcharge;
    } else {
      currentOd.publicHolidaySurcharge = '0';
      currentOd.publicHolidaySurchargePer = '0';
    }
  } else {
    currentOd.publicHolidaySurcharge = '0';
    currentOd.publicHolidaySurchargePer = '0';
  }

  // GST
  if (orderSetup?.isDisplayGST && orderSetup?.isPriceIncludesGST) {
    tax = payableAmount / 11;
    currentOd.isDisplayGST = true;
    currentOd.isPriceIncludesGST = true;
  } else if (orderSetup?.isDisplayGST && !orderSetup?.isPriceIncludesGST) {
    tax = (payableAmount * 10) / 100;
    payableAmount = payableAmount + tax;
    currentOd.isDisplayGST = true;
    currentOd.isPriceIncludesGST = false;
  } else {
    currentOd.isDisplayGST = false;
  }

  // Credit card processing
  if (currentOd.paymentType === ORDER_PAYMENT_TYPE.card_online) {
    if (orderSetup.isCustomerLiableForOnlineCharges) {
      const { percentageCharge, fixedCharge } = getCardCharges({
        cards: creditCards,
        cardType: currentOd?.cardType,
        isInternationCard: currentOd?.isInternationCard,
      });
      creditCardCharges =
        parseFloat(payableAmount) * (percentageCharge / 100) + fixedCharge;

      creditCardCharges = Number(creditCardCharges).toFixed(2);
    }

    const payableAmountWithCreditCardCharges =
      +payableAmount + +creditCardCharges;

    // Platform charges processing
    if (
      orderSetup.isPlatformSurchargeLevied &&
      orderSetup.platformSurchargeAmt &&
      currentOd.orderType !== MENU_ORDER_TYPE.dinein
    ) {
      isPlatformSurchargeLevied = true;
      platformSurchargePercentage =
        Number(orderSetup.platformSurchargePercentage) || 0;
      platformSurchargeAmt =
        parseFloat(payableAmountWithCreditCardCharges) *
          (platformSurchargePercentage / 100) +
        Number(orderSetup.platformSurchargeAmt);
    }

    if (
      currentOd?.isGfoOrder &&
      orderSetup.gfoSurchargeEnabled &&
      currentOd.orderType !== MENU_ORDER_TYPE.dinein
    ) {
      const gfoSurchargePercentage = Number(orderSetup.gfoSurchargeRate) || 0;
      const gfoSurchargeAmt =
        (parseFloat(payableAmountWithCreditCardCharges) +
          parseFloat(platformSurchargeAmt)) *
        (gfoSurchargePercentage / 100);
      platformSurchargeAmt = platformSurchargeAmt + gfoSurchargeAmt;
      platformSurchargeAmt = Number(platformSurchargeAmt).toFixed(2);
    }
  } else {
    creditCardCharges = 0;
    platformSurchargeAmt = 0;
  }

  // Order's payable amount
  payableAmount = parseFloat(payableAmount).toFixed(2);
  totalCost = parseFloat(totalCost).toFixed(2);

  currentOd.totalCost = totalCost;
  currentOd.payableAmount = payableAmount;
  currentOd.creditCardCharges = Number(creditCardCharges).toFixed(2);
  currentOd.platformSurchargeAmt = Number(platformSurchargeAmt).toFixed(2);
  currentOd.platformSurchargePercentage = Number(
    platformSurchargePercentage
  ).toFixed(2);
  currentOd.isPlatformSurchargeLevied = isPlatformSurchargeLevied;
  currentOd.gst = Number(tax).toFixed(2);
  currentOd.deliveryCost = deliveryCost?.toString() || '0';
  currentOd.specialDiscount = specialDiscount.toString();
  currentOd.upsalePrice = parseFloat(upsalePrice).toFixed(2);
  currentOd.loyaltyDiscount = loyaltyDiscount.toString();
  currentOd.specials = specials;
  currentOd.rewards = rewards;
  currentOd.offers = offerFrames;
  currentOd.isFreeItemsAvailable = offerFrames.length > rewards.length;
  currentOd.voucherItems = currentOd.voucherId ? userSelectedVoucherItems : [];

  // Reset voucher if discount is zero
  // if (Number(voucherDiscount) <= 0) {
  //   currentOd.voucherDiscount = '0';
  //   currentOd.voucherId = '';
  //   currentOd.voucherName = '';
  //   currentOd.selectedVoucher = {};
  // } else {
  currentOd.voucherDiscount = voucherDiscount.toString();
  currentOd.voucherName =
    matchingVouchers && matchingVouchers.length > 0
      ? matchingVouchers[0].voucherCode
      : '';
  // }

  // Check order is good to move to review screen or not
  if (currentOd.menuItems && currentOd.menuItems.length > 0) {
    let excludedDiscountFromMinOrderValue = 0;
    if (
      Number(currentOd?.selectedVoucher?.excludeDiscountFromMinOrderValue) > 0
    ) {
      excludedDiscountFromMinOrderValue = Number(currentOd?.voucherDiscount);
    }
    if (Number(currentOd?.loyaltyDiscount) > 0) {
      const exculdedOffersFromMinOrderValue = [];
      currentOd?.offers.forEach((offer) => {
        if (offer?.excludeDiscountFromMinOrderValue) {
          exculdedOffersFromMinOrderValue.push(offer?._id);
        }
      });
      if (exculdedOffersFromMinOrderValue?.length > 0) {
        currentOd?.rewards.forEach((reward) => {
          if (exculdedOffersFromMinOrderValue.includes(reward?._id)) {
            excludedDiscountFromMinOrderValue += Number(reward?.discount);
          }
        });
      }
    }

    const totalCostForDelivery = (
      Number(currentOd.payableAmount) +
      excludedDiscountFromMinOrderValue -
      Number(currentOd.deliveryCost ? currentOd.deliveryCost : 0)
    ).toFixed(2);

    const totalCostForPickup = (
      Number(currentOd.payableAmount) + excludedDiscountFromMinOrderValue
    ).toFixed(2);

    const suburb = order?.allSuburbs?.find((s) => {
      return s._id === currentOd.suburbId;
    });

    const minDeliveryValue = orderSetup?.useRadiusBasedDeliveryAreaCalulation
      ? Number(currentOd.minOrderValue)
      : Number(suburb?.minOrderValue) ||
        Number(orderSetup?.minDeliveryOrderValue);

    if (
      currentOd.orderType === MENU_ORDER_TYPE.pickup &&
      orderSetup &&
      totalCostForPickup >=
        Number(
          orderSetup.minPickupOrderValue ? orderSetup.minPickupOrderValue : 0
        )
    ) {
      currentOd.isAllowToConfirm = true;
    } else if (
      currentOd.orderType === MENU_ORDER_TYPE.delivery &&
      orderSetup &&
      totalCostForDelivery >= minDeliveryValue &&
      currentOd.address !== ''
    ) {
      currentOd.isAllowToConfirm = true;
    } else if (
      currentOd.orderType === MENU_ORDER_TYPE.dinein &&
      currentOd.tableId
    ) {
      currentOd.isAllowToConfirm = true;
    } else currentOd.isAllowToConfirm = false;
  } else currentOd.isAllowToConfirm = false;

  return { currentOd, rowItems };
}

/* =================================><================================== */
/**
 * @param selectedSpecials {state.selectedSpecials}
 */
export function engineDoASelfMerge(selectedSpecials) {
  let mergedItems = [],
    specialIndexIds = [];
  let itemsPresent = selectedSpecials
    ? JSON.parse(JSON.stringify(selectedSpecials))
    : [];
  itemsPresent.forEach((item, i) => {
    if (!includes(specialIndexIds, item.boxIndex)) {
      let otherItemsPresent = itemsPresent.filter(
        (m) => m._id === item._id && m.boxIndex !== item.boxIndex && !m.isHalf
      );
      if (otherItemsPresent.length > 0) {
        let mergedItemIntermidient = item;
        otherItemsPresent.forEach((ot, index) => {
          if (detectMergeRequired(ot, mergedItemIntermidient)) {
            index === 0
              ? (mergedItemIntermidient = performMerge(ot, item))
              : (mergedItemIntermidient = performMerge(
                  ot,
                  mergedItemIntermidient
                ));
            specialIndexIds.push(ot.boxIndex);
          }
        });
        mergedItems.push(mergedItemIntermidient);
      } else if (item.isHalf && !item.isSecondHalf) {
        const anotherHalf = itemsPresent[i + 1];

        if (anotherHalf && anotherHalf.isSecondHalf) {
          item.halfIndex = i;
          anotherHalf.halfIndex = i;
          mergedItems.push(item);
          mergedItems.push(anotherHalf);
        }
      } else if (!item.isHalf) {
        mergedItems.push(item);
      }
    }
  });
  return mergedItems;
}

/* =================================><================================== */
/**
 * @param item item to add
 * @param props
 */
export function engineAddToOrder(item, props) {
  const { currentOrder } = props;
  let isItemmerged = false;
  let orderObj = JSON.parse(JSON.stringify(currentOrder));
  let menuItems = orderObj.menuItems;
  let existingItems = menuItems.filter((m) => m._id === item._id);
  if (existingItems && existingItems.length > 0) {
    let calculations;

    for (let ext of existingItems) {
      if (isItemmerged) break;
      if (detectMergeRequired(item, ext)) {
        let mergedItem = performMerge(item, ext, props.halfHalfSetup);
        if (item.isVoucherItem) {
          ext.isVoucherItem = true;
          ext.voucherId = item.voucherId;
        }
        menuItems = menuItems.map((menuItem) =>
          menuItem._id === item._id &&
          mergedItem.orderIndex === menuItem.orderIndex
            ? {
                ...menuItem,
                ...mergedItem,
              }
            : {
                ...menuItem,
              }
        );

        orderObj.menuItems = menuItems;
        calculations = engineGetOrderCalculations(orderObj, props);
        isItemmerged = true;
      } else {
        orderObj.menuItems = [...menuItems, item];
        calculations = engineGetOrderCalculations(orderObj, props);
      }
    }
    return calculations;
  } else {
    orderObj.menuItems = [...menuItems, item];
    let calculations = engineGetOrderCalculations(orderObj, props);

    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param items items to add
 * @param props
 */
export function engineAddItemsToOrder(items, props) {
  const { currentOrder } = props;
  let orderObj = JSON.parse(JSON.stringify(currentOrder));
  let menuItems = orderObj.menuItems;
  items.forEach((item) => {
    let isItemMerged = false;
    let existingItems = orderObj.menuItems.filter((m) => m._id === item._id);
    if (existingItems && existingItems.length > 0) {
      existingItems.forEach((ext) => {
        if (!isItemMerged) {
          if (detectMergeRequired(item, ext)) {
            let mergedItem = performMerge(item, ext);
            if (item.isVoucherItem) {
              mergedItem.isVoucherItem = true;
              mergedItem.voucherId = item.voucherId;
            }
            menuItems = menuItems.map((m) => {
              if (m._id === item._id) {
                return { ...mergedItem };
              }
              return m;
            });
            isItemMerged = true;
          }
        }
      });
      if (!isItemMerged) {
        menuItems.push(item);
        isItemMerged = true;
      }
    } else {
      menuItems.push(item);
      isItemMerged = true;
    }
  });
  orderObj.menuItems = menuItems;
  let calculations = engineGetOrderCalculations(orderObj, props);

  return calculations;
}
/* =================================><================================== */
/**
 * @param table items to add
 * @param props
 */
export function engineAddTableToOrder(table, props) {
  const { currentOrder } = props;

  const { name, number, id, floorLayoutId } = table;

  const orderObj = {
    ...currentOrder,
    orderType: MENU_ORDER_TYPE.dinein,
    tableNumber: name || number,
    tableId: id,
    floorLayoutId: floorLayoutId,
    dineInObjectId: id,
  };
  const calculations = engineGetOrderCalculations(orderObj, props);

  return calculations;
}

/* =================================><================================== */
/**
 * @param itemFH
 * @param itemSH
 * @param halfHalfAdditionalCharges
 * @param props
 */
export function engineAddHalfItemsToOrder(
  itemFH,
  itemSH,
  halfHalfAdditionalCharges,
  props
) {
  const { currentOrder } = props;
  let currentOd = JSON.parse(JSON.stringify(currentOrder));
  let menuItems = currentOd.menuItems;
  let existingItems = menuItems.filter((m) => m._id === itemFH._id && m.isHalf);
  let existingItemsSH = menuItems.filter(
    (m) => m._id === itemSH._id && m.isHalf
  );
  if (
    existingItems &&
    existingItems.length > 0 &&
    existingItemsSH &&
    existingItemsSH.length > 0
  ) {
    if (
      detectHalfMergeRequired(
        itemFH,
        itemSH,
        existingItems[0],
        existingItemsSH[0]
      )
    ) {
      let mergedItem = performMerge(itemFH, existingItems[0]);
      menuItems.forEach((m) => {
        if (m._id === itemFH._id) {
          m = { ...mergedItem };
        }
      });
      let mergedItemSH = performMerge(itemSH, existingItemsSH[0]);
      menuItems.forEach((m) => {
        if (m._id === itemSH._id) {
          m = { ...mergedItemSH };
        }
      });
    } else {
      menuItems.push(itemFH);
      menuItems.push(itemSH);
    }
  } else {
    menuItems.push(itemFH);
    menuItems.push(itemSH);
  }
  currentOd.menuItems = menuItems;
  currentOd.halfHalfAdditionalCharges = halfHalfAdditionalCharges.toFixed(2);
  let calculations = engineGetOrderCalculations(currentOd, props);
  return calculations;
}

/* =================================><================================== */
/**
 * @param props
 */
export function engineRemoveOutOfScheduleItems(props) {
  let { menuItemSchedules, currentOrder } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let menuItems = [];
  let halfItems = [];
  let notAvailableMenuItems = {};
  menuItemSchedules?.forEach(({ menuItemId, isAvailableNow }) => {
    if (!notAvailableMenuItems[menuItemId]) {
      notAvailableMenuItems[menuItemId] = isAvailableNow;
    }
  });
  notAvailableMenuItems = removeTruthyProperties(notAvailableMenuItems);
  menuItems = ctOrder?.menuItems?.filter(({ _id, isHalf, halfIndex }) => {
    if (isHalf && notAvailableMenuItems[_id]) {
      halfItems.push({ halfIndex });
    }
    return notAvailableMenuItems[_id] && !isHalf;
  });

  ctOrder.menuItems = menuItems;

  if (halfItems.length > 0) {
    let items = [];
    ctOrder.menuItems.forEach((p) => {
      let matchingHalfIndexs = halfItems.filter((h) => {
        return h.halfIndex === p.halfIndex;
      });
      if (matchingHalfIndexs.length <= 0) items.push(p);
    });
    ctOrder.menuItems = items;
  }

  if (ctOrder.menuItems.length <= 0) {
    ctOrder.stage = 0;
  }

  const calculations = engineGetOrderCalculations(ctOrder, props);
  return calculations;
}

/* =================================><================================== */
/**
 * @param props
 */
export function engineRemoveOtherThanSelectedOrderTypeItems(props) {
  let { currentOrder } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  const orderType = `${ctOrder?.orderType}`;
  let menuItems = [];
  let halfItems = [];

  menuItems = ctOrder?.menuItems?.filter((menuItem) => {
    const { halfIndex, isHalf, orderTypeKeys } = menuItem;

    const isAvailable = orderTypeKeys
      ?.map((otk) => `${otk}`)
      .includes(orderType);

    if (isHalf && !isAvailable) {
      halfItems.push({ halfIndex });
    }
    return isAvailable;
  });

  ctOrder.menuItems = menuItems;

  if (halfItems.length > 0) {
    let items = [];
    ctOrder.menuItems.forEach((p) => {
      let matchingHalfIndexs = halfItems.filter((h) => {
        return h.halfIndex === p.halfIndex;
      });
      if (matchingHalfIndexs.length <= 0) items.push(p);
    });
    ctOrder.menuItems = items;
  }

  if (ctOrder.menuItems.length <= 0) {
    ctOrder.stage = 0;
  }

  const calculations = engineGetOrderCalculations(ctOrder, props);
  return calculations;
}

/* =================================><================================== */
/**
 * @param props
 */
export function engineRemoveSoldOutItems(props) {
  const { currentOrder, soldOutMenuItems } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let halfItems = [];

  ctOrder.menuItems.forEach((item) => {
    const isSoldOut = isMenuItemSoldOut(item, soldOutMenuItems);
    item.isDelete = isSoldOut;
    if (item.isHalf) {
      halfItems.push(item.halfIndex);
    }
  });

  let menuItems = ctOrder.menuItems.filter((p) => !p.isDelete);
  ctOrder.menuItems = menuItems;

  if (halfItems.length > 0) {
    let items = [];
    ctOrder.menuItems.forEach((p) => {
      let matchingHalfIndexs = halfItems.filter((h) => h === p.halfIndex);
      if (matchingHalfIndexs.length <= 0) items.push(p);
    });
    ctOrder.menuItems = items;
  }

  if (ctOrder.menuItems.length <= 0) {
    ctOrder.stage = 0;
  }

  const calculations = engineGetOrderCalculations(ctOrder, props);
  return calculations;
}

/* =================================><================================== */
/**
 * @param arrObj
 * @param spMenuSizes, spSubModifiers
 */
export function engineShowSpecialItem(arrObj, spMenuSizes, spSubModifiers) {
  if (!arrObj || !(spMenuSizes && spSubModifiers)) return;

  let obj = JSON.parse(JSON.stringify(arrObj));
  if (obj.selectedIngredients) {
    obj.selectedIngredients = obj.selectedIngredients.map((i) => {
      i.inOrder = true;
      return i;
    });
  }

  let activeSizes = spMenuSizes.filter((o) => o.isActive === true);
  let activeSubModifiers = spSubModifiers.filter((o) => o.isActive === true);
  if (obj.selectedSizes && obj.selectedSizes.length === 1) {
    obj.selectedSizes = obj?.selectedSizes?.map((item) => {
      if (!item.quantity) {
        item.quantity = 1;
        item.isDisplayForSpecialOrHalfHalf = true;
      }
      item.isSingleSize = true;
      return item;
    });
  } else {
    let selectedItems = [];
    obj.selectedSizes = obj?.selectedSizes?.map((item) => {
      const matchingActiveSizeExists = activeSizes.some(
        (s) => s._id === item._id && s.isActive
      );
      const matchingActiveSubModifierExists = activeSubModifiers.some(
        (s) => s._id === item._id && s.isActive
      );
      if (matchingActiveSizeExists || matchingActiveSubModifierExists) {
        item.isDisplayForSpecialOrHalfHalf = true;
        selectedItems.push(item);
      }
      return item;
    });
    if (selectedItems.length === 1) {
      obj.selectedSizes.forEach((item) => {
        if (item._id === selectedItems[0]._id) {
          item.quantity = 1;
          item.isDisplayForSpecialOrHalfHalf = true;
        } else item.isDisplayForSpecialOrHalfHalf = false;
      });
      obj.isDisplayForSpecialOrHalfHalf = false;
    } else {
      obj.isDisplayForSpecialOrHalfHalf = true;
    }
  }
  obj.isSpecialItem = true;
  obj.isHalf = false;

  return obj;
}

/* =================================><================================== */
/**
 * @param arrObj
 * @param state {itemVariants, hhActiveMenuSizes} state.itemVariants
 */
export function engineShowHalfItem(arrObj, state) {
  const { itemVariants, hhActiveMenuSizes } = state;

  let obj = JSON.parse(JSON.stringify(arrObj));
  if (obj.selectedIngredients) {
    obj.selectedIngredients.forEach((i) => {
      i.inOrder = true;
    });
  }

  if (obj.itemType === MENU_ITEM_TYPE.complex) {
    obj.selectedModifiers = obj?.selectedModifiers?.map((m) => {
      m.subModifiers = m.subModifiers?.map((item) => {
        if (
          !item.quantity &&
          hhActiveMenuSizes &&
          hhActiveMenuSizes.map((m) => m._id).includes(item._id)
        ) {
          item.quantity = 0.5;
          if (item.variants) {
            item.variants.forEach((v) => {
              let matchingVariants = itemVariants?.filter(
                (ss) => ss._id === v._id
              );
              if (matchingVariants.length > 0) {
                v.quantity = 0.5;
                v.isSelected = true;
              }
            });
          }
          item.isDisplayForSpecialOrHalfHalf = true;
        } else {
          item.isDisplayForSpecialOrHalfHalf = false;
        }
        return item;
      });
      return m;
    });
  }

  if (obj.selectedSizes && obj.selectedSizes.length === 1) {
    obj.selectedSizes = obj?.selectedSizes?.map((item) => {
      if (!item.quantity) item.quantity = 0.5;
      item.isSingleSize = true;
      if (item.variants) {
        item.variants.forEach((v) => {
          let matchingVariants = itemVariants?.filter((ss) => ss._id === v._id);
          if (matchingVariants.length > 0) {
            v.quantity = 0.5;
            v.isSelected = true;
          }
        });
      }
      item.isDisplayForSpecialOrHalfHalf = true;
      return item;
    });
  } else {
    obj.selectedSizes = obj?.selectedSizes?.map((item) => {
      if (
        !item.quantity &&
        hhActiveMenuSizes &&
        hhActiveMenuSizes.map((m) => m._id).includes(item._id)
      ) {
        item.quantity = 0.5;
        if (item.variants) {
          item.variants.forEach((v) => {
            let matchingVariants = itemVariants?.filter(
              (ss) => ss._id === v._id
            );
            if (matchingVariants.length > 0) {
              v.quantity = 0.5;
              v.isSelected = true;
            }
          });
        }
        item.isDisplayForSpecialOrHalfHalf = true;
      } else {
        item.isDisplayForSpecialOrHalfHalf = false;
      }
      return item;
    });
  }
  obj.isHalf = true;
  obj.itemVariants = itemVariants;

  return obj;
}

/* =================================><================================== */
/**
 * @param hhActiveMenuSizes
 * @param itemVariants
 * @param stateSelectedHalfItems state.selectedHalfItems
 * @param menuItems
 */
export function engineClearOrChangeSize(
  hhActiveMenuSizes,
  itemVariants,
  stateSelectedHalfItems,
  menuItems
) {
  let newHalfObject = [];
  if (stateSelectedHalfItems && menuItems) {
    let selectedHalfItems = JSON.parse(JSON.stringify(stateSelectedHalfItems));
    if (selectedHalfItems.length >= 1 && selectedHalfItems[0]._id) {
      let firstHalf = selectedHalfItems[0];
      let matchingItems = menuItems.filter((m) => m._id === firstHalf._id);
      if (matchingItems.length > 0) {
        let menuItem = JSON.parse(JSON.stringify(matchingItems[0]));
        let matchingSizes = menuItem.selectedSizes.filter((f) =>
          hhActiveMenuSizes.map((m) => m._id).includes(f._id)
        );
        if (matchingSizes.length > 0) {
          let matchingSize = matchingSizes[0];
          if (itemVariants.length <= 0) {
            matchingSize.quantity = 0.5;
            firstHalf.selectedSizes = [{ ...matchingSize, variants: [] }];
            if (firstHalf.itemType === 1) {
              firstHalf.selectedModifiers.forEach((sm) => {
                if (firstHalf.baseModifierId === sm._id && sm.subModifiers) {
                  sm.subModifiers = [{ ...matchingSize, variants: [] }];
                }
              });
            }
            newHalfObject.push(firstHalf);
          } else {
            if (matchingSize.variants) {
              let cnt = 0;
              matchingSize.variants.forEach((sv) => {
                let matchingVar = itemVariants.filter((f) => f._id === sv._id);
                if (matchingVar.length > 0) cnt = cnt + 1;
              });
              if (cnt === itemVariants.length) {
                matchingSize.quantity = 0.5;
                matchingSize.variants.forEach((sv) => {
                  let matchingVar = itemVariants.filter(
                    (f) => f._id === sv._id
                  );
                  if (matchingVar.length > 0) sv.quantity = 0.5;
                });
                firstHalf.selectedSizes = [matchingSize];
                //Complex type
                if (firstHalf.itemType === 1) {
                  firstHalf.selectedModifiers.forEach((sm) => {
                    if (
                      firstHalf.baseModifierId === sm._id &&
                      sm.subModifiers
                    ) {
                      sm.subModifiers = [matchingSize];
                    }
                  });
                }
                newHalfObject.push(firstHalf);
              }
            }
          }
        }
      }
    }
    if (selectedHalfItems.length >= 2 && selectedHalfItems[1]._id) {
      let secondHalf = selectedHalfItems[1];
      let matchingItems = menuItems.filter((m) => m._id === secondHalf._id);
      if (matchingItems.length > 0) {
        let menuItem = JSON.parse(JSON.stringify(matchingItems[0]));
        let matchingSizes = menuItem.selectedSizes.filter((f) =>
          hhActiveMenuSizes.map((m) => m._id).includes(f._id)
        );
        if (matchingSizes.length > 0) {
          let matchingSize = matchingSizes[0];
          matchingSize.quantity = 0.5;
          if (itemVariants.length <= 0) {
            matchingSize.quantity = 0.5;
            secondHalf.selectedSizes = [{ ...matchingSize, variants: [] }];
            //Complex type
            if (secondHalf.itemType === 1) {
              secondHalf.selectedModifiers.forEach((sm) => {
                if (secondHalf.baseModifierId === sm._id && sm.subModifiers) {
                  sm.subModifiers = [{ ...matchingSize, variants: [] }];
                }
              });
            }
            newHalfObject.push(secondHalf);
          } else {
            if (matchingSize.variants) {
              let cnt = 0;
              matchingSize.variants.forEach((sv) => {
                let matchingVar = itemVariants.filter((f) => f._id === sv._id);
                if (matchingVar.length > 0) cnt = cnt + 1;
              });
              if (cnt === itemVariants.length) {
                matchingSize.quantity = 0.5;
                matchingSize.variants.forEach((sv) => {
                  let matchingVar = itemVariants.filter(
                    (f) => f._id === sv._id
                  );
                  if (matchingVar.length > 0) sv.quantity = 0.5;
                });
                secondHalf.selectedSizes = [matchingSize];
                //Complex type
                if (secondHalf.itemType === 1) {
                  secondHalf.selectedModifiers.forEach((sm) => {
                    if (
                      secondHalf.baseModifierId === sm._id &&
                      sm.subModifiers
                    ) {
                      sm.subModifiers = [matchingSize];
                    }
                  });
                }
                newHalfObject.push(secondHalf);
              }
            }
          }
        }
      }
    }
    return { selectedHalfItems: newHalfObject, itemVariants };
  } else {
    return { itemVariants };
  }
}

/* =================================><================================== */
/**
 * @param arrObj
 * @param soldOutMenuItems
 */
export function engineShowMenuItemHandler(arrObj, soldOutMenuItems) {
  let obj = arrObj ? JSON.parse(JSON.stringify(arrObj)) : {};
  obj.selectedIngredients?.forEach((i) => {
    if (i.isRemoved) {
      i.inOrder = false;
    } else {
      i.inOrder = true;
    }
  });
  if (obj.selectedSizes && obj.selectedSizes.length === 1) {
    let isSoldOut = false;
    if (soldOutMenuItems) {
      isSoldOut =
        obj.itemType === 1
          ? obj.selectedModifiers.some((j) =>
              j.subModifiers.every((sm) => {
                const basesSoldOut = soldOutMenuItems.some(
                  (k) =>
                    k.smId === sm._id &&
                    k.menuItemId === obj._id &&
                    k.variantId === null
                );

                const variants = sm.variants || [];

                const variantsSoldOut =
                  variants.lenth === 0
                    ? false
                    : (sm.variants || []).every((v) =>
                        soldOutMenuItems.some(
                          (l) =>
                            l.smId === sm._id &&
                            l.menuItemId === obj._id &&
                            l.variantId === v._id
                        )
                      );

                return basesSoldOut && variantsSoldOut;
              })
            )
          : obj.selectedSizes.every((s) => {
              const basesSoldOut = soldOutMenuItems.some(
                (k) =>
                  k.smId === s._id &&
                  k.menuItemId === obj._id &&
                  k.variantId === null
              );

              const variants = s.variants || [];

              const variantsSoldOut =
                variants.lenth === 0
                  ? false
                  : (s.variants || []).every((v) =>
                      soldOutMenuItems.some(
                        (k) =>
                          k.smId === s._id &&
                          k.menuItemId === obj._id &&
                          k.variantId === v._id
                      )
                    );

              return basesSoldOut && variantsSoldOut;
            });
    }
    obj.isSoldOut = isSoldOut;
    obj.selectedSizes = obj?.selectedSizes?.map((item) => {
      if (isSoldOut) return item;
      if (!item.quantity) item.quantity = 1;
      item.isSingleSize = true;
      return item;
    });
  }
  obj.isHalf = false;

  return obj;
}

/* =================================><================================== */
/**
 * @param sizeObj
 * @param itemObj
 * @param props
 */
export function engineIncrementItemQuantity(sizeObj, itemObj, props) {
  const { currentOrder, halfHalfSetup } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let menuItem = ctOrder.menuItems.filter(
    (menuItem) =>
      menuItem._id === itemObj._id && itemObj.orderIndex === menuItem.orderIndex
  );
  if (itemObj.isHalf && menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedSizes?.forEach((item) => {
      if (item._id === sizeObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity + 0.5;
        } else {
          item.quantity = 0.5;
        }

        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = v.quantity + 0.5;
          }
        });
      }
    });

    // increase global menu item quantity
    currentItem.quantity = currentItem.quantity + 0.5;

    // Complex item
    if (currentItem.itemType === 1) {
      currentItem.selectedModifiers.forEach((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          modifier.subModifiers.forEach((s) => {
            if (s._id === sizeObj._id) {
              if (s.quantity) {
                s.quantity = s.quantity + 0.5;
              } else {
                s.quantity = 0.5;
              }
            }
          });
        } else {
          modifier.subModifiers.forEach((s) => {
            s.prices?.forEach((p) => {
              if (p.quantity) p.quantity = p.quantity + 0.5;
              else p.quantity = 1;

              p.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity + 0.5;
                else v.quantity = 1;
              });
            });
          });
        }
      });
    }
    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });
    let itemsSF = ctOrder.menuItems.filter(
      (menuItem) =>
        menuItem._id !== currentItem._id &&
        itemObj.halfIndex === menuItem.halfIndex
    );
    if (itemsSF && itemsSF.length > 0) {
      let ctM = itemsSF[0];
      ctM.selectedSizes.forEach((item) => {
        // if (item._id === sizeObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity + 0.5;
        } else {
          item.quantity = 0.5;
        }
        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = v.quantity + 0.5;
          }
        });

        // }
      });

      // increase global menu item quantity
      ctM.quantity = currentItem.quantity + 0.5;

      // Complex item
      if (ctM.itemType === 1) {
        ctM.selectedModifiers.forEach((modifier) => {
          if (modifier._id === ctM.baseModifierId) {
            modifier.subModifiers.forEach((s) => {
              if (s._id === sizeObj._id) {
                if (s.quantity) {
                  s.quantity = s.quantity + 0.5;
                } else {
                  s.quantity = 0.5;
                }
              }
            });
          } else {
            modifier.subModifiers.forEach((s) => {
              s.prices?.forEach((p) => {
                if (p.quantity) p.quantity = p.quantity + 0.5;
                else p.quantity = 1;
                p.variants?.forEach((v) => {
                  if (v.quantity) v.quantity = v.quantity + 0.5;
                  else v.quantity = 1;
                });
              });
            });
          }
        });
      }
      ctOrder.menuItems.forEach((m) => {
        if (m._id === ctM._id) {
          m = ctM;
        }
      });
    }
    let charges = currentOrder.halfHalfAdditionalCharges
      ? Number(currentOrder.halfHalfAdditionalCharges)
      : 0;
    let negCharges =
      halfHalfSetup && halfHalfSetup.halfHalfAdditionalCharges
        ? Number(halfHalfSetup.halfHalfAdditionalCharges)
        : 0;
    charges = charges + negCharges;
    ctOrder.halfHalfAdditionalCharges = charges.toFixed(2);
    let calculations = engineGetOrderCalculations(ctOrder, props);
    // setState({ currentOrder: newOrder });
    return calculations;
  } else if (menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedSizes.forEach((item) => {
      if (item._id === sizeObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity + 1;
        } else {
          item.quantity = 1;
        }

        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = v.quantity + 1;
          }
        });
      }
    });

    // increase global menu item quantity
    currentItem.quantity = currentItem.quantity + 1;

    // Complex item
    if (itemObj.itemType === 1) {
      currentItem.selectedModifiers.forEach((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          modifier.subModifiers.forEach((s) => {
            if (s._id === sizeObj._id) {
              if (s.quantity) {
                s.quantity = s.quantity + 1;
              } else {
                s.quantity = 1;
              }
              s.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity + 1;
                else v.quantity = 1;
              });
            }
          });
        } else {
          modifier.subModifiers.forEach((s) => {
            s.prices?.forEach((p) => {
              if (p.quantity) p.quantity = p.quantity + 1;
              else p.quantity = 1;

              p.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity + 1;
                else v.quantity = 1;
              });
            });
          });
        }
      });
    }
    const itemPrice = Number(getItemPrice(currentItem, halfHalfSetup));
    currentItem.itemPrice = itemPrice;
    currentItem.payablePrice = itemPrice;
    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });

    let calculations = engineGetOrderCalculations(ctOrder, props);
    // setState({ currentOrder: newOrder });
    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param sizeObj
 * @param itemObj
 * @param props
 */
export function engineSetItemQuantity(sizeObj, itemObj, quantity, props) {
  const { currentOrder, halfHalfSetup } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let menuItem = ctOrder.menuItems.filter(
    (menuItem) => menuItem._id === itemObj._id
  );
  if (menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedSizes.forEach((item) => {
      if (item._id === sizeObj._id) {
        if (item.quantity) {
          item.quantity = quantity ? quantity : item.quantity + 1;
        } else {
          item.quantity = 1;
        }

        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = quantity ? quantity : v.quantity + 1;
          }
        });
      }
    });

    // increase global menu item quantity
    currentItem.quantity = quantity ? quantity : currentItem.quantity + 1;

    // Complex item
    if (itemObj.itemType === 1) {
      currentItem.selectedModifiers.forEach((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          modifier.subModifiers.forEach((s) => {
            if (s._id === sizeObj._id) {
              if (s.quantity) {
                s.quantity = quantity ? quantity : s.quantity + 1;
              } else {
                s.quantity = quantity ? quantity : 1;
              }
              s.variants?.forEach((v) => {
                if (v.quantity)
                  v.quantity = quantity ? quantity : v.quantity + 1;
                else v.quantity = 1;
              });
            }
          });
        } else {
          modifier.subModifiers.forEach((s) => {
            s.prices?.forEach((p) => {
              if (p.quantity) p.quantity = quantity ? quantity : p.quantity + 1;
              else p.quantity = quantity ? quantity : 1;

              p.variants?.forEach((v) => {
                if (v.quantity)
                  v.quantity = quantity ? quantity : v.quantity + 1;
                else v.quantity = quantity ? quantity : 1;
              });
            });
          });
        }
      });
    }
    const itemPrice = Number(getItemPrice(currentItem, halfHalfSetup));
    currentItem.itemPrice = itemPrice;
    currentItem.payablePrice = itemPrice;
    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });

    let calculations = engineGetOrderCalculations(ctOrder, props);
    // setState({ currentOrder: newOrder });
    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param item
 * @param state {selectedSpecials, specialItem}
 * @param boxIndex
 */
export function engineAddToSpecial(item, state, boxIndex) {
  const { selectedSpecials, specialItem, voucherItem } = state;

  let newList = [];
  let itemsPresent = selectedSpecials
    ? JSON.parse(JSON.stringify(selectedSpecials))
    : [];
  let existItems = itemsPresent.some((f) => f.boxIndex === boxIndex);

  if (existItems) {
    newList = itemsPresent.filter((m) => m.boxIndex !== boxIndex);
  } else {
    newList = itemsPresent;
  }

  if (Array.isArray(item)) {
    // adding half-half to special
    item.forEach((i) => {
      i.isSpecialItem = true;
      if (!voucherItem) i.specialId = specialItem._id;
      else i.voucherId = voucherItem._id;
      i.boxIndex = boxIndex;
      i.quantity = 0.5;
      newList.push(i);
    });
  } else {
    if (!voucherItem) item.specialId = specialItem._id;
    else item.voucherId = voucherItem._id;
    item.boxIndex = boxIndex;
    // XXX: FIX FOR REALLY HARD SIDE-EFFECT BUG
    item.isHalf = false;
    item.quantity = 1;
    if (Array.isArray(item?.selectedSizes) && item.selectedSizes[0]) {
      item.selectedSizes[0].quantity = 1;
      item.itemPrice = parseFloat(item.selectedSizes[0].price);
      item.payablePrice = parseFloat(item.selectedSizes[0].price);
    }

    newList.push(item);
  }

  return newList;
}

/* =================================><================================== */
/**
 * @param item
 * @param specialItem
 */
export function engineMultipleAddToSpecial(items, specialItem) {
  let newList = [];

  items.forEach((item) => {
    item.specialId = specialItem._id;
    // XXX: FIX FOR REALLY HARD SIDE-EFFECT BUG
    item.isHalf = false;
    item.quantity = 1;
    if (Array.isArray(item?.selectedSizes) && item.selectedSizes[0]) {
      item.selectedSizes[0].quantity = 1;
      item.itemPrice = parseFloat(item.selectedSizes[0].price);
      item.payablePrice = parseFloat(item.selectedSizes[0].price);
    }

    newList.push(item);
  });

  return newList;
}

/* =================================><================================== */
/**
 * @param item
 * @param state {selectedHalfItems, whichHalf}
 * @param halfHalfSetup
 */
export function engineAddToHalfHalf(item, state, halfHalfSetup) {
  const { selectedHalfItems, whichHalf } = state;

  let itemsPresent = JSON.parse(JSON.stringify(selectedHalfItems));
  let chargeExpensiveHalf = halfHalfSetup.chargeExpensiveHalf;
  item.whichHalf = whichHalf;
  if (itemsPresent.length < 2) itemsPresent.push(item);
  else itemsPresent[whichHalf - 1] = item;
  if (chargeExpensiveHalf) {
    let price = 0;
    //Find out price for more expensive item
    itemsPresent.forEach((item) => {
      if (item) {
        if (item.itemType === 1) {
          //Complex Item
          item.selectedModifiers.forEach((modifier) => {
            if (modifier._id === item.baseModifierId) {
              modifier.subModifiers.forEach((subModifier) => {
                if (
                  subModifier.quantity > 0 &&
                  Number(subModifier.price) > price
                ) {
                  price = Number(subModifier.price);
                }
              });
            }
          });
        } else {
          //Simple Item
          item.selectedSizes.forEach((size) => {
            if (size.quantity > 0 && Number(size.price) > price) {
              price = Number(size.price);
            }
          });
        }
      }
    });

    //Update Price
    itemsPresent.forEach((item) => {
      if (item) {
        if (item.itemType === 1) {
          //Complex Item
          item.selectedModifiers.forEach((modifier) => {
            if (modifier._id === item.baseModifierId) {
              modifier.subModifiers.forEach((subModifier) => {
                if (subModifier.quantity > 0) {
                  subModifier.price = Number(price).toFixed(2);
                }
              });
            }
          });
        }
        item.selectedSizes.forEach((size) => {
          if (size.quantity > 0) {
            size.price = Number(price).toFixed(2);
          }
        });
      }
    });
  }

  return itemsPresent;
}

/* =================================><================================== */
/**
 * @param extraObj
 * @param itemObj
 * @param whichHalf
 * @param props
 */
export function engineIncrementExtraQuantity(
  extraObj,
  itemObj,
  whichHalf,
  props
) {
  const { currentOrder } = props;

  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let menuItem = ctOrder.menuItems.filter(
    (menuItem) =>
      menuItem._id === itemObj._id && itemObj.orderIndex === menuItem.orderIndex
  );
  if (itemObj.isHalf && menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    if (whichHalf === '1') {
      currentItem.selectedExtraIngredients.forEach((item) => {
        if (item._id === extraObj._id) {
          if (item.quantity) {
            item.quantity = item.quantity + 1;
          } else {
            item.quantity = 1;
          }
        }
      });
      ctOrder.menuItems.forEach((m) => {
        if (m._id === currentItem._id) {
          m = currentItem;
        }
      });
    }
    if (whichHalf === '2') {
      let itemsSF = ctOrder.menuItems.filter(
        (menuItem) =>
          menuItem._id !== currentItem._id &&
          itemObj.halfIndex === menuItem.halfIndex
      );
      if (itemsSF && itemsSF.length > 0) {
        let ctM = itemsSF[0];
        ctM.selectedExtraIngredients.forEach((item) => {
          if (item._id === extraObj._id) {
            if (item.quantity) {
              item.quantity = item.quantity + 1;
            } else {
              item.quantity = 1;
            }
          }
        });
        ctOrder.menuItems.forEach((m) => {
          if (m._id === ctM._id) {
            m = ctM;
          }
        });
      }
    }
    let calculations = engineGetOrderCalculations(ctOrder, props);
    return calculations;
  } else if (menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedExtraIngredients.forEach((item) => {
      if (item._id === extraObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity + 1;
        } else {
          item.quantity = 1;
        }
      }
    });
    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });
    let calculations = engineGetOrderCalculations(ctOrder, props);
    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param extraObj
 * @param itemObj
 * @param props
 */
export function engineDecrementItemQuantity(sizeObj, itemObj, props) {
  const { currentOrder, halfHalfSetup } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let menuItem = ctOrder.menuItems.filter(
    (menuItem) =>
      menuItem._id === itemObj._id && itemObj.orderIndex === menuItem.orderIndex
  );
  if (itemObj.isHalf && menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedSizes.forEach((item) => {
      if (item._id === sizeObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity - 0.5;
        }

        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = v.quantity - 0.5;
          }
        });
      }
    });

    //symotion-prefix) decrease global menu item quantity
    currentItem.quantity = currentItem.quantity - 0.5;

    // Complex item
    if (currentItem.itemType === 1) {
      currentItem.selectedModifiers.forEach((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          modifier.subModifiers.forEach((s) => {
            if (s._id === sizeObj._id) {
              if (s.quantity) s.quantity = s.quantity - 0.5;
              else s.quantity = 0;

              s.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity - 0.5;
                else v.quantity = 0;
              });
            }
          });
        } else {
          modifier.subModifiers.forEach((s) => {
            s.prices?.forEach((p) => {
              if (p.quantity) p.quantity = p.quantity - 0.5;
              else p.quantity = 0;

              p.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity - 0.5;
                else v.quantity = 0;
              });
            });
          });
        }
      });
    }
    currentItem.selectedSizes = currentItem.selectedSizes.filter(
      (s) => s.quantity && Number(s.quantity) > 0
    );
    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });

    let itemsSF = ctOrder.menuItems.filter(
      (menuItem) =>
        menuItem.whichHalf !== itemObj.whichHalf &&
        itemObj.halfIndex === menuItem.halfIndex
    );

    if (itemsSF && itemsSF.length > 0) {
      let ctM = itemsSF[0];
      ctM.selectedSizes.forEach((item) => {
        if (item.quantity) {
          item.quantity = item.quantity - 0.5;
        }

        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = v.quantity - 0.5;
          }
        });
      });

      // decrease global menu item quantity
      ctM.quantity = ctM.quantity - 1;

      // Complex item
      if (ctM.itemType === 1) {
        ctM.selectedModifiers.forEach((modifier) => {
          if (modifier._id === ctM.baseModifierId) {
            modifier.subModifiers.forEach((s) => {
              if (s._id === sizeObj._id) {
                if (s.quantity) s.quantity = s.quantity - 0.5;
                else s.quantity = 0;

                s.variants?.forEach((v) => {
                  if (v.quantity) v.quantity = v.quantity - 0.5;
                  else v.quantity = 0;
                });
              }
            });
          } else {
            modifier.subModifiers.map((s) => {
              if (s.prices) {
                s.prices.map((p) => {
                  if (p.quantity) p.quantity = p.quantity - 0.5;
                  else p.quantity = 0;
                  if (p.variants) {
                    p.variants.map((v) => {
                      if (v.quantity) v.quantity = v.quantity - 0.5;
                      else v.quantity = 0;
                      return v;
                    });
                  }
                  return p;
                });
              }
              return s;
            });
          }
        });
      }
      ctM.selectedSizes = ctM.selectedSizes.filter(
        (s) => s.quantity && Number(s.quantity) > 0
      );

      ctOrder.menuItems.forEach((m) => {
        if (m._id === ctM._id) {
          m = ctM;
        }
      });
    }
    let charges = currentOrder.halfHalfAdditionalCharges
      ? Number(currentOrder.halfHalfAdditionalCharges)
      : 0;
    let negCharges =
      halfHalfSetup && halfHalfSetup.halfHalfAdditionalCharges
        ? Number(halfHalfSetup.halfHalfAdditionalCharges)
        : 0;
    charges = charges !== 0 ? charges - negCharges : 0;
    ctOrder.halfHalfAdditionalCharges = charges.toFixed(2);
    ctOrder.menuItems = ctOrder.menuItems.filter(
      (m) => m.selectedSizes && m.selectedSizes.length > 0
    );
    const calculations = engineGetOrderCalculations(ctOrder, props);

    return calculations;
  } else if (menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedSizes.forEach((item) => {
      if (item._id === sizeObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity - 1;
        }

        item.variants?.forEach((v) => {
          if (v.quantity) {
            v.quantity = v.quantity - 1;
          }
        });
      }
    });
    currentItem.selectedSizes = currentItem.selectedSizes.filter(
      (s) => s.quantity && Number(s.quantity) > 0
    );

    // decrease global menu item quantity
    currentItem.quantity = currentItem.quantity - 1;

    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });

    // Complex item
    if (itemObj.itemType === 1) {
      currentItem.selectedModifiers.forEach((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          modifier.subModifiers.forEach((s) => {
            if (s._id === sizeObj._id) {
              if (s.quantity) s.quantity = s.quantity - 1;
              else s.quantity = 0;

              s.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity - 1;
                else v.quantity = 0;
              });
            }
          });
        } else {
          modifier.subModifiers.forEach((s) => {
            s.prices?.forEach((p) => {
              if (p.quantity) p.quantity = p.quantity - 1;
              else p.quantity = 0;

              p.variants?.forEach((v) => {
                if (v.quantity) v.quantity = v.quantity - 1;
                else v.quantity = 0;
              });
            });
          });
        }
      });
    }

    const itemPrice = Number(getItemPrice(currentItem, halfHalfSetup));
    currentItem.itemPrice = itemPrice;
    currentItem.payablePrice = itemPrice;

    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });

    ctOrder.menuItems = ctOrder.menuItems.filter(
      (m) => m.selectedSizes && m.selectedSizes.length > 0
    );
    const calculations = engineGetOrderCalculations(ctOrder, props);

    return calculations;
  }
}

/* =================================><================================== */

export function engineRemoveItemFromOrder(itemObj, props) {
  const { currentOrder } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));

  if (itemObj.isHalf) {
    ctOrder.menuItems = ctOrder.menuItems.filter(
      (menuItem) => itemObj.halfIndex !== menuItem.halfIndex
    );
  } else {
    ctOrder.menuItems = ctOrder.menuItems.filter(
      (menuItem) => itemObj.orderIndex !== menuItem.orderIndex
    );
  }

  const calculations = engineGetOrderCalculations(ctOrder, props);

  return calculations;
}

/* =================================><================================== */
/**
 * @param extraObj
 * @param itemObj
 * @param whichHalf
 * @param props
 */

export function engineDecrementExtraQuantity(
  extraObj,
  itemObj,
  whichHalf,
  props
) {
  const { currentOrder } = props;
  let ctOrder = JSON.parse(JSON.stringify(currentOrder));
  let menuItem = ctOrder.menuItems.filter(
    (menuItem) =>
      menuItem._id === itemObj._id && itemObj.orderIndex === menuItem.orderIndex
  );
  if (itemObj.isHalf && menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    if (whichHalf === '1') {
      currentItem.selectedExtraIngredients.forEach((item) => {
        if (item._id === extraObj._id) {
          if (item.quantity) {
            item.quantity = item.quantity - 1;
          }
        }
      });
      currentItem.selectedExtraIngredients =
        currentItem.selectedExtraIngredients.filter(
          (s) => s.quantity && Number(s.quantity) > 0
        );
      ctOrder.menuItems.forEach((m) => {
        if (m._id === currentItem._id) {
          m = currentItem;
        }
      });
    }
    if (whichHalf === '2') {
      let itemsSF = ctOrder.menuItems.filter(
        (menuItem) =>
          menuItem._id !== itemObj._id &&
          itemObj.halfIndex === menuItem.halfIndex
      );
      if (itemsSF && itemsSF.length > 0) {
        let ctM = itemsSF[0];
        ctM.selectedExtraIngredients.forEach((item) => {
          if (item._id === extraObj._id) {
            if (item.quantity) {
              item.quantity = item.quantity - 1;
            }
          }
        });
        ctM.selectedExtraIngredients = ctM.selectedExtraIngredients.filter(
          (s) => s.quantity && Number(s.quantity) > 0
        );
        ctOrder.menuItems.forEach((m) => {
          if (m._id === ctM._id) {
            m = ctM;
          }
        });
      }
    }
    ctOrder.menuItems = reMergeHalfItems(ctOrder.menuItems, itemObj);
    let calculations = engineGetOrderCalculations(ctOrder, props);
    return calculations;
  } else if (menuItem && menuItem.length > 0) {
    let currentItem = menuItem[0];
    currentItem.selectedExtraIngredients.forEach((item) => {
      if (item._id === extraObj._id) {
        if (item.quantity) {
          item.quantity = item.quantity - 1;
        }
      }
    });
    if (currentItem.selectedExtraIngredients) {
      currentItem.selectedExtraIngredients =
        currentItem.selectedExtraIngredients.filter(
          (m) => m.quantity && Number(m.quantity) > 0
        );
    }
    ctOrder.menuItems.forEach((m) => {
      if (m._id === currentItem._id) {
        m = currentItem;
      }
    });
    ctOrder.menuItems = reMergeItems(ctOrder.menuItems, currentItem);
    let calculations = engineGetOrderCalculations(ctOrder, props);
    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param addressObj
 * @param suburb
 * @param areaCode
 * @param area
 * @param props
 */
export function engineUpdateOrderDeliveryAddress(
  addressObj,
  suburb,
  areaCode,
  area,
  props
) {
  const { currentOrder } = props;
  if (
    addressObj &&
    addressObj.label &&
    addressObj.location &&
    addressObj.placeId
  ) {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    orderObj.address = addressObj.label.trim();
    orderObj.addressLocation = addressObj.location;
    orderObj.placeId = addressObj.placeId;
    orderObj.suburbId = suburb._id;
    orderObj.deliveryCost = suburb.deliveryCost.toString();
    orderObj.minOrderValue = suburb.minOrderValue ? suburb.minOrderValue : '0';
    orderObj.areaCode = areaCode;
    orderObj.area = area;
    orderObj.deliveryData = suburb;
    let calculations = engineGetOrderCalculations(orderObj, props);
    return calculations;
  } else {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    orderObj.address = '';
    orderObj.addressLocation = '';
    orderObj.suburbId = '';
    orderObj.deliveryCost = '';
    orderObj.minOrderValue = '0';
    orderObj.areaCode = '';
    orderObj.area = '';
    let calculations = engineGetOrderCalculations(orderObj, props);
    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param deliveryDate
 * @param shiftId
 * @param isCartConfirm
 * @param stage
 * @param isFutureDeliveryDate
 * @param props
 */
export function engineUpdateDeliveryPickupTime(
  deliveryDate,
  shiftId,
  isCartConfirm,
  stage,
  isFutureDeliveryDate,
  props
) {
  const { storeConfig, currentUser, userDetails, currentOrder } = props;
  let orderObj = JSON.parse(JSON.stringify(currentOrder));
  if (isCartConfirm) {
    if (
      stage === ORDER_STAGE_TYPES.NAME_FORM &&
      (currentUser?.phoneNumber || userDetails?.mobileNumber)
    ) {
      orderObj.deliveryDate = deliveryDate.format('YYYY-MM-DD HH:mm');
      orderObj.deliveryDateTimestamp = getTimestampForMomentDate(
        deliveryDate,
        storeConfig.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      orderObj.shiftId = shiftId;
      orderObj.stage = ORDER_STAGE_TYPES.REVIEW;
      orderObj.isFutureDeliveryDate = isFutureDeliveryDate;
      let calculations = engineGetOrderCalculations(orderObj, props);
      return calculations;
    } else {
      orderObj.deliveryDate = deliveryDate.format('YYYY-MM-DD HH:mm');
      orderObj.deliveryDateTimestamp = getTimestampForMomentDate(
        deliveryDate,
        storeConfig.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      orderObj.shiftId = shiftId;
      orderObj.stage = stage;
      orderObj.isFutureDeliveryDate = isFutureDeliveryDate;
      let calculations = engineGetOrderCalculations(orderObj, props);
      return calculations;
    }
  } else {
    orderObj.deliveryDate = deliveryDate.format('YYYY-MM-DD HH:mm');
    orderObj.deliveryDateTimestamp = getTimestampForMomentDate(
      deliveryDate,
      storeConfig.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    orderObj.shiftId = shiftId;
    orderObj.isFutureDeliveryDate = isFutureDeliveryDate;
    let calculations = engineGetOrderCalculations(orderObj, props);
    return calculations;
  }
}

/* =================================><================================== */
/**
 * @param cardDescription
 * @param cardNumber
 * @param cardType
 * @param isSameCard
 * @param isCardDetailsValid
 * @param token
 * @param cardCountry
 * @param props
 */
export function engineUpdatePaymenyType(
  cardDescription,
  cardNumber,
  cardType,
  isSameCard,
  isCardDetailsValid,
  token,
  cardCountry,
  props
) {
  const { storeConfig, currentOrder } = props;
  let orderObj = JSON.parse(JSON.stringify(currentOrder));
  if (isSameCard) {
    if (isCardDetailsValid)
      orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
    else {
      orderObj.paymentType = ORDER_PAYMENT_TYPE.unpaid;
      orderObj.cardType = '1000';
      orderObj.cardNumber = '';
      orderObj.cardDescription = '';
      orderObj.isInternationCard = false;
      orderObj.token = '';
    }
  } else {
    orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
    orderObj.cardType = cardType.toString();
    orderObj.isInternationCard =
      !cardCountry ||
      cardCountry?.toLowerCase() ===
        storeConfig.countryIdentifier?.toLowerCase()
        ? false
        : true;
    orderObj.cardNumber = cardNumber;
    orderObj.cardDescription = cardDescription;
    orderObj.token = token;
  }
  let calculations = engineGetOrderCalculations(orderObj, props);
  return calculations;
}

/* =================================><================================== */
/**
 * @param isCardDetailsValid
 * @param card
 * @param props
 */
export function engineUpdatePaymentTypeForNewCard(
  isCardDetailsValid,
  card,
  props
) {
  const { currentOrder, storeConfig } = props;
  let orderObj = JSON.parse(JSON.stringify(currentOrder));
  if (isCardDetailsValid) {
    orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
    orderObj.cardType = card.type;
    orderObj.isInternationCard =
      !card.country ||
      card.country?.toLowerCase() ===
        storeConfig.countryIdentifier.toLowerCase()
        ? false
        : true;
    orderObj.cardNumber = card.number;
    orderObj.cardDescription = card.type;
  } else {
    orderObj.paymentType = ORDER_PAYMENT_TYPE.unpaid;
    orderObj.cardNumber = '';
    orderObj.cardType = '';
    orderObj.isInternationCard = false;
    orderObj.cardDescription = '';
  }
  let calculations = engineGetOrderCalculations(orderObj, props);
  return calculations;
}

/* =================================><================================== */
/**
 * @param state {selectedHalfItems, itemVariants}
 * @param props { currentOrder, halfHalfSetup }
 */
export function engineAddHalfHalfToOrder(state, props) {
  const { selectedHalfItems, itemVariants } = state;
  const { currentOrder, halfHalfSetup } = props;
  if (!(selectedHalfItems && selectedHalfItems.length >= 2)) return;

  let halfIndex = getNextHalfIndex(currentOrder);

  let firstItem = JSON.parse(JSON.stringify(selectedHalfItems[0]));

  if (itemVariants && itemVariants.length > 0) {
    firstItem.selectedSizes = firstItem.selectedSizes.map((s) => {
      s.variants?.forEach((v) => {
        if (v.isSelected) {
          let matchingVariants = itemVariants.filter((vv) => vv._id === v._id);
          if (matchingVariants.length > 0) {
            v.quantity = s.quantity;
            v.isSelected = true;
          } else {
            v.isSelected = false;
            v.quantity = 0;
          }
        } else {
          v.isSelected = false;
          v.quantity = 0;
        }
      });

      return s;
    });
  } else {
    firstItem.selectedSizes = firstItem.selectedSizes.map((s) => {
      if (Array.isArray(s.variants)) {
        s.variants.forEach((v) => {
          v.isSelected = false;
          v.quantity = 0;
        });
      }
      return s;
    });
  }

  firstItem.halfIndex = halfIndex;
  firstItem.isSecondHalf = false;

  let secondItem = JSON.parse(JSON.stringify(selectedHalfItems[1]));

  if (itemVariants && itemVariants.length > 0) {
    secondItem.selectedSizes = secondItem.selectedSizes.map((s) => {
      s.variants?.forEach((v) => {
        if (v.isSelected) {
          let matchingVariants = itemVariants.filter((vv) => vv._id === v._id);
          if (matchingVariants.length > 0) {
            v.quantity = s.quantity;
            v.isSelected = true;
          } else {
            v.isSelected = false;
            v.quantity = 0;
          }
        } else {
          v.isSelected = false;
          v.quantity = 0;
        }
      });

      return s;
    });
  } else {
    secondItem.selectedSizes = secondItem.selectedSizes.map((s) => {
      if (Array.isArray(s.variants)) {
        s.variants.forEach((v) => {
          v.isSelected = false;
          v.quantity = 0;
        });
      }
      return s;
    });
  }

  secondItem.halfIndex = halfIndex;
  secondItem.isSecondHalf = true;

  let charges = currentOrder.halfHalfAdditionalCharges
    ? Number(currentOrder.halfHalfAdditionalCharges)
    : 0;
  let newCharges =
    halfHalfSetup && halfHalfSetup.halfHalfAdditionalCharges
      ? Number(halfHalfSetup.halfHalfAdditionalCharges) + Number(charges)
      : Number(charges);

  return {
    firstItem,
    secondItem,
    newCharges,
  };
}
